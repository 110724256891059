import React, { useState } from 'react';

import { FaBlog, FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import { HiOutlineDocumentText } from "react-icons/hi";
import { BiSpreadsheet } from "react-icons/bi";
import { Snackbar, Alert } from '@mui/material';           
import validator from 'validator';
import { useNavigate } from 'react-router-dom';

import { api } from '../../services/api';

import styles from './footer.module.scss';

export default function Footer() {
    const navigate = useNavigate();
    
    const [emailNewsletter, setEmailNewsletter] = useState('');
    const [typeEmailMessage, setTypeEmailMessage] = useState('');
    const [openEmailMessage, setOpenEmailMessage] = useState(false);

    function handleNewsletter() {
        if(emailNewsletter && validator.isEmail(emailNewsletter)) {
            api.post('/nobeta/newsletter', {
                email: emailNewsletter
            }).then((res) => {
                if(res.data.result === 'Email já cadastrado') {
                    setTypeEmailMessage('error');
                } else {
                    setTypeEmailMessage('success');
                }

                setOpenEmailMessage(true);
            })
        } else {
            setOpenEmailMessage(true);
            setTypeEmailMessage('non-exist');
        }
    }

    return(
        <div className={styles.footer}>
            <Snackbar 
                autoHideDuration={3000} 
                anchorOrigin={{vertical: 'top', horizontal: 'center'}} 
                onClose={() => setOpenEmailMessage(false)} 
                open={openEmailMessage}
            >
                <Alert onClose={() => setOpenEmailMessage(false)} severity={typeEmailMessage === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {typeEmailMessage === 'success' ? 'Email cadastrado com sucesso' : typeEmailMessage === 'error' ? 'Email já cadastrado' : 'Email inválido'}
                </Alert>
            </Snackbar>

            <p className={styles.paragraphtitle}>© nobeta 2022. Todos os direitos reservados. <span onClick={() => navigate('politica-privacidade')}>Política de Privacidade</span></p>
        
            <div className={styles.inline}>
                <ul className={styles.links}>
                    <li>
                        <a rel="noreferrer" target="_blank" href="https://blog.nobeta.com.br/" className={styles.link}>
                            <FaBlog color="#fff" size={15} />
                            <span>Blog da nobeta</span>
                        </a>
                    </li>

                    <li>
                        <a rel="noreferrer" target="_blank" href="https://www.facebook.com/NoBetaAds/" className={styles.link}>
                            <FaFacebook color="#fff" size={15} />
                            <span>Facebook</span>
                        </a>
                    </li>

                    <li>
                        <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/nobeta/" className={styles.link}>
                            <FaLinkedin color="#fff" size={15} />
                            <span>Linkedin</span>
                        </a>
                    </li>

                    <li>
                        <a rel="noreferrer" target="_blank" href="https://instagram.com/nobeta.ads" className={styles.link}>
                            <FaInstagram color="#fff" size={15} />
                            <span>Instagram</span>
                        </a>
                    </li>

                    <li>
                        <a onClick={() => navigate('/documentacao', { state: {categoriaFromPage: 0}})} className={styles.link}>
                            <HiOutlineDocumentText color="#fff" size={15} />
                            <span>Documentação</span>
                        </a>
                    </li>

                    <li>
                        <a rel="noreferrer" target="_blank" href="/exemplos" className={styles.link}>
                            <BiSpreadsheet color="#fff" size={15} />
                            <span>Exemplos</span>
                        </a>
                    </li>
                </ul>

                <div className={styles.line}></div>

                <div className={styles.newsletter}>
                    <span>Newsletter</span>
                    <div className={styles.inputArea}>
                        <input style={{borderColor: typeEmailMessage === 'error' || typeEmailMessage === 'non-exist' ? 'var(--red)' : 'var(--secondary)'}} onChange={v => setEmailNewsletter(v.target.value)} placeholder="Digite seu email" />
                        <button onClick={handleNewsletter}>Inscrever</button>
                    </div>

                    <p>Receba informações exclusiva da nobeta. Nós nunca disponibilizaremos nossa lista para serviços terceiros, você pode requisitar a remoção do seu endereço a qualquer momento.</p>
                </div>
            </div>
        </div>
    )
}