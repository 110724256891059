import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GrAnnounce, GrContact } from 'react-icons/gr';
import { FaUserFriends, FaBlog } from 'react-icons/fa';
import { FiMonitor } from 'react-icons/fi';

import MenuIcon from '@rsuite/icons/Menu';
import CloseIcon from '@rsuite/icons/Close';

import styles from './header.module.scss';

import Logo32 from '../../assets/images/logo-32x32.png';

import Logo100 from '../../assets/images/logo-100x32.png';
import Logo2x from '../../assets/images/logo-2x.png';
import Logo3x from '../../assets/images/logo-3x.png';

export default function Header() {
    const navigate = useNavigate();

    const [openMenu, setOpenMenu] = useState(false);
    
    return(
        <div className={styles.header}>
            <img onClick={() => navigate('/')} src={Logo100} srcSet={`${Logo2x} 2x, ${Logo3x} 3x`} width={100} height={32} alt={"Logo Nobeta"} className={styles.logoHeader} />

            <div className={styles.nav}>
                <a rel="noreferrer" href={"/anunciantes"} target="_blank">Seja um Anunciante</a>
                <a rel="noreferrer" href={"https://parceria.nobeta.com.br/"} target="_blank">Seja um Parceiro</a>
                <a rel="noreferrer" href={"https://blog.nobeta.com.br/"} target="_blank">Blog da Nobeta</a>
                <a rel="noreferrer" href={"#contact"}>Fale Conosco</a>
                <a rel="noreferrer" href={"https://report.nobeta.com.br/"} target="_blank">Painel do parceiro</a>
            </div>

            <div onClick={() => setOpenMenu(!openMenu)} className={styles.menuButton}>
                {!openMenu ?
                    <MenuIcon style={{width: '1.5rem', height: '1.5rem'}} color={"gray"}/>
                    :
                    <CloseIcon style={{width: '1.5rem', height: '1.5rem'}} color={"gray"}/>
                }
            </div>

            <nav className={openMenu ? "nav-menu active" : "nav-menu"}>
                <div>
                    <img src={Logo32} alt={"Logo Nobeta 32"} className={styles.logoMenu} />
                </div>

                <a rel="noreferrer" className={styles.menuLink} href="/anunciantes">
                    <GrAnnounce style={{marginRight: '.7rem'}} color="var(--black)" size={18} />
                    Seja um Anunciante
                </a>
                <a rel="noreferrer" className={styles.menuLink} href="https://parceria.nobeta.com.br/">
                    <FaUserFriends style={{marginRight: '.7rem'}} color="var(--black)" size={18} />
                    Seja um Parceiro
                </a>
                <a rel="noreferrer" className={styles.menuLink} href="https://blog.nobeta.com.br/" target="_blank">
                    <FaBlog style={{marginRight: '.7rem'}} color="var(--black)" size={18} />
                    Blog da Nobeta
                </a>
                <a rel="noreferrer" className={styles.menuLink} href="#contact">
                    <GrContact style={{marginRight: '.7rem'}} color="var(--black)" size={18} />
                    Fale Conosco
                </a>
                <a rel="noreferrer" className={styles.menuLink} href="https://report.nobeta.com.br/" target="_blank">
                    <FiMonitor style={{marginRight: '.7rem'}} color="var(--black)" size={18} />
                    Painel do parceiro
                </a>
            </nav>

            {/*<div>*/}
            {/*    <div onClick={() => setOpenMenu(false)} className={styles.closeButton}>*/}
            {/*        <CloseIcon style={{width: '1.5rem', height: '1.5rem'}} color={"gray"}/>*/}
            {/*    </div>*/}

            {/*    <div className={styles.options}>*/}
            {/*        <a rel="noreferrer" href={"#"}>Seja um Anunciante</a>*/}
            {/*        <a rel="noreferrer" href={"#"}>Seja um Parceiro</a>*/}
            {/*        <a rel="noreferrer" href={"#"}>Blog da Nobeta</a>*/}
            {/*        <a rel="noreferrer" href={"#"}>Fale Conosco</a>*/}
            {/*        <a rel="noreferrer" href={"#"}>Painel do parceiro</a>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div> 
    )
}