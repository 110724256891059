import React, { useEffect } from "react" 

import { useParams } from 'react-router-dom';

import styles from './formato.css';
 
export default function Intercontent() {
    let { formato } = useParams();

    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "getFormato";
        script.src = `https://api.nobeta.com.br/nobetaads&id=nbtcase&f=${formato}`;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
        // <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
        // <script src="https://api.nobeta.com.br/nobetaads&id=nbtcase&f=intercontent"></script>
    }, [])

    return(
        <body>
            <div id={styles.template}>
                <div id="header"></div>

                <div id="content">
                    <div id="main">
                        <div id="title"></div>
                        <div id="subtitle"></div>
                        <div id="nbtpreparagraph" style={{marginTop: formato === 'preparagraph' ? 20 : 0}}></div>
                        <div id="image">
                            <div id="nbtio">
                                <div class="ads"></div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div id="nbtdtm"></div>
                        <div class="line"></div>
                        <div class="linef"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="linef"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="linef"></div>
                    </div>
                    <div id="side">
                        <div class="news"></div>
                        <div id="nbtdt"></div>
                        <div class="news"></div>
                        <div class="news"></div>
                    </div>
                </div>
                
                <div id="formato">
                    <div class="topbar"></div>
                </div>
            </div>
        </body>
    )
}
