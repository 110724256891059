import React, { useState, useEffect } from 'react';

import { Loader } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';           

import { FaPlug, FaHandHoldingUsd, FaWindowRestore, FaGraduationCap, FaHandshake, FaGlobe, FaPoll, FaMoneyBillWaveAlt, FaQuestionCircle } from 'react-icons/fa';

import { api } from '../../services/api';

import Menu from '../../components/Menu';

import styles from './pagina.module.scss';

// interface pagina {
//     id?: number;
//     titulo?: string;
//     pageId?: number;
// }

export default function Quests() {
    const navigate = useNavigate();
    // const location = useLocation();

    const [categoriaInt, setCategoriaInt] = useState(0);
    const [categorias, setCategorias] = useState([]);
    const [smallMenu, setSmallMenu] = useState(false);

    // const [stateData, setStateData] = useState<pagina>(location.state);
    const [banner, setBanner] = useState('artigo');
    const [categoria, setCategoria] = useState('');
    const [id, setId] = useState(0);
    const [pageId, setPageId] = useState(0);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');

    const [feedback, setFeedback] = useState(false);
    const [feedbackValue, setFeedbackValue] = useState(0);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const [feedbackLoader, setFeedbackLoader] = useState(9999);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [feedbackSended, setFeedbackSended] = useState(false); 
    const [openFeedbackSuccess, setOpenFeedbackSuccess] = useState(false);

    useEffect(() => {
        const urlExplod = decodeURI(window.location.pathname).split("/");

        // const titleToSearch = urlExplod[4].replace("-", " ");
        setId(parseInt(urlExplod[3]));

        api.get(`/documentation/categoria/${urlExplod[2]}`).then((res: any) => {
            const data = res.data.result;

            setCategoria(data.titulo);
        })

        api.get(`/documentation/pagina/${urlExplod[3]}`).then((res: any) => {
            const data = res.data.result;

            setPageId(data.id);
            setTitle(data.titulo);
            setText(data.texto);
            setBanner(`https://cdn.nobeta.com.br/docs/icons/banner_${data.icone}.jpg`)
        })

        api.get('/documentation/categorias').then((res: any) => {
            const data = res.data;

            setCategorias(data.result);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleFeedbackValue(value: number) {
        setFeedbackValue(value);
        setFeedbackLoader(value);

        setTimeout(() => {
            setFeedback(true);
            setFeedbackLoader(49);
        }, 2000)
    };

    function handleFeedback() {
        setButtonLoader(true);

        api.post('/documentation/send-feedback', {
            pageid: pageId,
            value: feedbackValue,
            message: feedbackMessage
        }).then((res: any) => {
            const data = res.data.result;
            
            if(data) {
                setFeedbackSended(true);
                setOpenFeedbackSuccess(true);
                setButtonLoader(false);
            }
        })
    }

    function handleCategoria(id: number) {
        navigate('/documentacao', { 
            state: {categoriaFromPage: id}
        });
    } 

    return(
        <div className={styles.container}>
            <Snackbar 
                autoHideDuration={3000} 
                anchorOrigin={{vertical: 'top', horizontal: 'center'}} 
                onClose={() => setOpenFeedbackSuccess(false)} 
                open={openFeedbackSuccess}
            >
                <Alert onClose={() => setOpenFeedbackSuccess(false)} severity='success' sx={{ width: '100%' }}>
                    Obrigado! Recebos seu feedback.
                </Alert>
            </Snackbar>

            <div style={{backgroundImage: `url(${banner})`}} className={styles.banner}>
                <div className={styles.lefttext}>
                    <p><span onClick={() => navigate('/documentacao', { state: { categoriaFromPage: 0} })}>Home</span> {">"} {categoria}</p>

                    <h1>{title}</h1>
                </div>
            </div>

            <section>
                <Menu goBack={true} smallMenu={smallMenu} setSmallMenu={setSmallMenu} categoria={categoriaInt} categorias={categorias} handleCategoria={handleCategoria} />

                <div className={styles.content}>
                    <div className={styles.title}>
                        {id === 1 ? <FaPlug color="var(--gray-900)" size={17} /> : null}
                        {id === 2 ? <FaHandHoldingUsd color="var(--gray-900)" size={17} /> : null}
                        {id === 3 ? <FaWindowRestore color="var(--gray-900)" size={17} /> : null}
                        {id === 4 ? <FaHandshake color="var(--gray-900)" size={17} /> : null}
                        {id === 5 ? <FaGlobe color="var(--gray-900)" size={17} /> : null}
                        {id === 6 ? <FaPoll color="var(--gray-900)" size={17} /> : null}
                        {id === 7 ? <FaMoneyBillWaveAlt color="var(--gray-900)" size={17} /> : null}
                        {id === 8 ? <FaQuestionCircle color="var(--gray-900)" size={17} /> : null}
                        {id === 9 ? <FaGraduationCap color="var(--gray-900)" size={17} /> : null}
                        <span>{categoria}</span>
                    </div>

                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: `${text}` }} />

                    <div className={styles.contentfooter}>
                        <span>Este artigo foi útil?</span>

                        <div className={styles.buttons}>
                            <button style={{backgroundColor: feedback && feedbackValue === 0 ? 'var(--gray-200)' : 'var(--secondary)'}} disabled={feedback} onClick={() => handleFeedbackValue(1)} className={styles.button}>
                                <span style={{display: feedbackLoader === 1 ? 'none' : null}}>Sim</span>

                                <Loader style={{display: feedbackLoader === 1 ? 'flex' : 'none'}} />
                            </button>

                            <button style={{backgroundColor: feedback && feedbackValue === 1 ? 'var(--gray-200)' : 'var(--secondary)'}} disabled={feedback} onClick={() => handleFeedbackValue(0)} className={styles.button}>
                                <span style={{display: feedbackLoader === 0 ? 'none' : null}}>Não</span>

                                <Loader style={{display: feedbackLoader === 0 ? 'flex' : 'none'}} />
                            </button>
                        </div>

                        <div style={{display: feedback ? 'flex' : 'none'}} className={styles.feedbackForm}>
                            <textarea onChange={v => setFeedbackMessage(v.target.value)} value={feedbackMessage} placeholder="Escreva seu feedback" />

                            <button style={{backgroundColor: feedbackSended ? 'var(--gray-200)' : 'var(--primary)'}} disabled={feedbackSended} onClick={handleFeedback} className={styles.sendbutton}>
                                <span style={{display: buttonLoader ? 'none' : null}}>Enviar feedback</span>
                                <Loader style={{display: buttonLoader ? 'flex' : 'none'}} />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}