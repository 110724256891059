import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';  
import { SelectPicker } from 'rsuite';

import { FaPlug, FaHandHoldingUsd, FaWindowRestore, FaGraduationCap, FaHandshake, FaGlobe, FaPoll, FaMoneyBillWaveAlt, FaQuestionCircle, FaBook, FaVideo } from 'react-icons/fa';

import { api } from '../../services/api';

import Menu from '../../components/Menu';

import styles from './documentation.module.scss';

export default function Documentation() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const stateFromPage = state;

    const [categoria, setCategoria] = useState(stateFromPage['categoriaFromPage'] ? stateFromPage['categoriaFromPage'] : 0);
    const [categoriaTitle, setCategoriaTitle] = useState('');
    const [paginas, setPaginas] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [smallMenu, setSmallMenu] = useState(false);

    useEffect(() => {
        api.get('/documentation/paginas').then((res: any) => {
            const data = res.data;

            setPaginas(data.result);
        });

        api.get('/documentation/categorias').then((res: any) => {
            const data = res.data;

            setCategorias(data.result);
        });
    }, []);

    function handleCategoria(id: number, titulo: string) {
        if(categoria === id) {
            setCategoria(0);
        } else {
            setCategoria(id);
        }

        setCategoriaTitle(titulo);
    } 

    return(
        <div className={styles.container}>
            <div className={styles.banner}>
                <div className={styles.lefttext}>
                    <h1>Central de ajuda nobeta</h1>

                    <p>Tire suas dúvidas e aprenda tudo sobre o funcionamento do sistema e boas práticas da nobeta</p>
                </div>
            </div>

            <section>
                <Menu goBack={false} smallMenu={smallMenu} setSmallMenu={setSmallMenu} categoria={categoria} categorias={categorias} handleCategoria={handleCategoria} />

                <div style={{width: smallMenu ? 'calc(100% - 3rem)' : 'calc(100% - 12rem)'}} className={styles.content}>
                    <div className={styles.header}>
                        <SelectPicker onSelect={(id, item) => {
                            const title = decodeURI(item.titulo.toLowerCase().replace(/[//"/ /]/g, '-'));

                            navigate(`${item.categoria}/${id}/${title}`, { state: {pageId: id, id: item.categoria, titulo: item.titulo}});
                        }} valueKey="id" placeholder="Pesquise" labelKey="titulo" data={paginas} style={{ width: 224, zIndex: 0, color: 'var(--black)' }}  />
                    </div>
                    
                    {categoria === 0 ?
                        categorias.map((itemCat, k) => (
                            <div key={k} className={styles.categorias}>
                                <div className={styles.title}>
                                    {itemCat.id === 1 ? <FaPlug color="var(--gray-900)" size={17} /> : null}
                                    {itemCat.id === 2 ? <FaHandHoldingUsd color="var(--gray-900)" size={17} /> : null}
                                    {itemCat.id === 3 ? <FaWindowRestore color="var(--gray-900)" size={17} /> : null}
                                    {itemCat.id === 4 ? <FaHandshake color="var(--gray-900)" size={17} /> : null}
                                    {itemCat.id === 5 ? <FaGlobe color="var(--gray-900)" size={17} /> : null}
                                    {itemCat.id === 6 ? <FaPoll color="var(--gray-900)" size={17} /> : null}
                                    {itemCat.id === 7 ? <FaMoneyBillWaveAlt color="var(--gray-900)" size={17} /> : null}
                                    {itemCat.id === 8 ? <FaQuestionCircle color="var(--gray-900)" size={17} /> : null}
                                    {itemCat.id === 9 ? <FaGraduationCap color="var(--gray-900)" size={17} /> : null}
                                    <span>{itemCat.titulo}</span>
                                </div>
    
                                <div className={styles.list}>
                                    {paginas.map((item: any, k: number) => (
                                        item.categoria === itemCat.id ?
                                            <div key={k} className={styles.item} onClick={() => {
                                                const title = decodeURI(item.titulo.toLowerCase().replace(/[//"/ /]/g, '-'));
    
                                                navigate(`${item.categoria}/${item.id}/${title}`, { state: {pageId: item.id, id: item.categoria, titulo: item.titulo}});
                                            }}>
                                                <img src={`https://cdn.nobeta.com.br/docs/icons/${item.icone}.jpg`} alt="banner" />
    
                                                <p>{item.titulo}</p>
    
                                                <div className={styles.itemfooter}>
                                                    {item.tipo === 'artigo' ?
                                                        <FaBook color="var(--gray-900)" size={17} />
                                                    :
                                                        <FaVideo color="var(--gray-900)" size={17} />
                                                    }
                                                    <span>{item.tipo}</span>
                                                </div>
                                            </div>
                                        :
                                            null
                                    ))}
                                </div>
                            </div>
                        ))
                        :
                        <div className={styles.categorias}>
                            <div className={styles.title}>
                                <FaHandHoldingUsd color="var(--gray-900)" size={17} />
                                <span>{categoriaTitle}</span>
                            </div>

                            <div className={styles.list}>
                                {paginas.map((item: any, k: number) => (
                                    item.categoria === categoria ?
                                        <div key={k} className={styles.item} onClick={() => {
                                            const title = decodeURI(item.titulo.toLowerCase().replace(/[//"/ /]/g, '-'));
                                            
                                            navigate(`${item.categoria}/${item.id}/${title}`, { state: {pageId: item.id, id: item.categoria, titulo: item.titulo}});
                                        }}>
                                            <img alt="icon" src={`https://cdn.nobeta.com.br/docs/icons/${item.icone}.jpg`} />

                                            <p>{item.titulo}</p>

                                            <div className={styles.itemfooter}>
                                                <FaBook color="var(--gray-900)" size={17} />
                                                <span>{item.tipo}</span>
                                            </div>
                                        </div>
                                        :
                                        null
                                ))}
                            </div>
                        </div>
                    }
                </div>
            </section>
        </div>
    )
}