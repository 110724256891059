import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://hmreport.nobeta.com.br/api'
});

// baseURL: 'https://hmreport.nobeta.com.br/api'

export const apiLocal = axios.create({
    baseURL: 'http://adm.local/api'
})

export const apiadm = axios.create({
    baseURL: 'https://adm.nobeta.com.br/api'
})