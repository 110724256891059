import React, { useEffect } from 'react';

import styles from './preview.module.scss';
import { useParams } from 'react-router-dom';

export default function Preview() {
  let { id } = useParams();

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "getExemplo";
    script.src = `https://s.nobeta.com.br/ps/tag_${id}.min.js`;
    document.body.appendChild(script);

    return () => {
        document.body.removeChild(script);
    };

    // <script type="module" origin="anonimous" src="https://s.nobeta.com.br/ps/tag_1.min.js"></script>
  }, []);

  return(
    <body>
      <nav>
        <a href="#">Notícias News</a>
      </nav>

      <section>
        <article>
          <h1>Lorem ipsum dolor sit amet consectetur adipiscing elit fringilla congue nullam sollicitudin</h1>

          <div id="nbtpreparagraph"></div>

          <figure>
            <img id={styles.previewimg} src="/dist/noticiasnews01.png" alt="Paisagem de São Paulo" className={styles.newsimage} />
            <figcaption>Paisagem de São Paulo</figcaption>
          </figure>
    
          <p>Mi lacus dis auctor turpis odio tellus vivamus arcu. Nisl fusce cum dis augue fames curae mi turpis sociis cursus facilisis aenean nam, vehicula habitasse congue dignissim eget senectus platea at porta quisque eros. Porta habitant aliquam sit magnis velit conubia mauris, fermentum suscipit mattis at netus metus, blandit dictum ante mollis elit rutrum. Velit hendrerit aenean non sociis aliquam conubia dolor pretium orci erat libero ultricies, suscipit a morbi maecenas ante dictum varius curae natoque donec condimentum, taciti nunc nostra sed facilisis fames vel amet nec per lacus. Purus cras euismod dui a aliquam vestibulum lacinia vitae vulputate, adipiscing libero varius egestas cubilia nascetur per gravida blandit, faucibus dapibus ut ultricies mi nulla tortor hac. </p>
    
          <p>Ultricies vitae class montes volutpat dictumst auctor vel gravida faucibus eu sociis pulvinar, fermentum nascetur facilisi tincidunt est ornare scelerisque convallis commodo suspendisse sodales. Enim augue magna torquent ut feugiat elit mauris at ornare ac sociosqu amet imperdiet platea vivamus eleifend, dictumst condimentum etiam consequat pellentesque nunc lobortis ullamcorper lorem lacinia nibh diam magnis suspendisse eros. Ad ut dictumst semper vel convallis dapibus, nisi malesuada imperdiet tempus pellentesque ante viverra, libero tortor taciti etiam habitant. Cras duis tortor metus nec lectus molestie dictumst habitasse feugiat amet massa, leo rutrum non auctor platea tempor nascetur dolor natoque enim, cum malesuada lorem vel phasellus nullam cubilia senectus pulvinar posuere. </p>
    
          <p>Himenaeos mauris donec vitae vivamus velit iaculis, mollis nibh ligula facilisis ante, proin tempor porttitor lorem magnis. Consectetur velit hac porta cum lectus pharetra fames, nullam torquent conubia vel habitant quam parturient, quis praesent tortor ut sociosqu ornare. Arcu montes leo dui lorem class vulputate vivamus blandit, vel iaculis quam curae nisi molestie venenatis, hac fringilla sociis auctor curabitur id integer. Lorem magnis fringilla erat venenatis etiam euismod dis sit curabitur nullam, non gravida laoreet vehicula libero adipiscing consequat penatibus a sollicitudin himenaeos, nisi turpis blandit dapibus sed natoque fusce maecenas nunc. </p>
    
          <p>Gravida nullam class magna rutrum lacus enim ridiculus nostra curae platea sapien tempor scelerisque dignissim porttitor neque, luctus lacinia sit proin molestie habitant urna faucibus eros tellus ad elementum etiam tincidunt nascetur. Lectus inceptos facilisi accumsan sodales blandit cursus mi est nisi senectus maecenas, nam sociis dignissim proin feugiat quam conubia dictum convallis neque hendrerit tellus, cum ridiculus per molestie eu cubilia hac aptent quisque eros. Faucibus ridiculus quis sodales cum dapibus sollicitudin mus ac malesuada vitae, leo mi hac augue cras netus convallis elementum dictumst justo, pulvinar erat habitasse at ornare id rutrum condimentum sociis. Ut rutrum leo purus varius vestibulum habitasse morbi primis congue molestie lacinia, fringilla consequat parturient id nibh duis mi litora gravida. </p>
    
          <p>Curae luctus posuere erat eros tincidunt torquent vivamus nulla vestibulum, himenaeos dis habitasse pellentesque ullamcorper vehicula vitae ipsum at, mauris lectus rutrum facilisis nisl hendrerit tortor libero. In dolor varius dictum curabitur diam dignissim at, cras mattis lacus praesent cubilia porttitor faucibus, class consequat elit quisque facilisis aptent. Condimentum dictum mauris ipsum amet nisl cubilia integer egestas eleifend, sed elementum eros magnis ullamcorper feugiat molestie ultricies. Penatibus senectus quis amet bibendum mus euismod adipiscing, aptent donec quisque sodales curae a vestibulum, viverra hendrerit porta montes convallis pellentesque. </p>
    
          <p>Elit hendrerit velit laoreet penatibus etiam lacinia donec litora tempus natoque vehicula, odio dictum eleifend mus netus gravida aliquam euismod proin. Semper eget cum metus primis vitae aliquet imperdiet integer fringilla, curae torquent fermentum natoque fames scelerisque curabitur. Orci vestibulum nulla lacinia netus nascetur class nibh amet urna, dictumst arcu enim nisl auctor varius montes pretium per ad, aliquam euismod gravida vitae eleifend congue tortor pulvinar. Natoque tortor congue turpis tellus velit magna et pretium, ridiculus quis mus rhoncus id semper erat odio, aptent ultricies phasellus primis tempor parturient mauris. Senectus justo commodo nulla quisque suspendisse neque vel penatibus dis lectus dolor sit fermentum consectetur fames est, natoque massa luctus vitae ultricies accumsan porta montes feugiat lacinia egestas parturient vehicula nisl. </p>
    
          <p>Dapibus dictumst natoque auctor habitant quis neque, platea convallis iaculis class eleifend lacinia vestibulum, fermentum taciti libero senectus penatibus. Nostra venenatis hendrerit scelerisque lobortis arcu torquent dolor ligula phasellus, purus enim facilisi condimentum dignissim litora metus eget aliquam ac, justo dictumst faucibus platea ullamcorper tellus sit ridiculus. Nulla aliquam hendrerit quis metus ridiculus suspendisse amet, integer augue velit taciti netus urna maecenas vivamus, sed nisi inceptos posuere tempor sit. Urna auctor enim netus sapien vitae laoreet eleifend euismod lorem, penatibus ultricies sociis eget vel hendrerit ullamcorper consequat, convallis lobortis dapibus platea pharetra justo nibh nisl. Sit feugiat metus vivamus facilisi consectetur imperdiet egestas aliquam rutrum, magna bibendum habitasse curae neque taciti justo vestibulum in luctus, turpis varius nibh platea volutpat maecenas dui gravida. </p>
        </article>

        <aside>
          
          <figure class="asidefigure">
            <img id={styles.previewimg} src="/dist/noticiasnews02.png" alt="Paisagem de São Paulo" />
            <figcaption>Lectus inceptos facilisi accumsan sodales blandit cursus</figcaption>
          </figure>

          <div id="nbtdt"></div>
          
          <figure class="asidefigure">
            <img id={styles.previewimg} src="/dist/noticiasnews03.png" alt="Paisagem de São Paulo" />
            <figcaption>Senectus justo commodo nulla quisque suspendisse neque</figcaption>
          </figure>
          
          <figure class="asidefigure">
            <img id={styles.previewimg} src="/dist/noticiasnews04.png" alt="Paisagem de São Paulo" />
            <figcaption>Orci vestibulum nulla lacinia netus</figcaption>
          </figure>
        

        </aside>

      </section>

      <footer>
        Notícias News &copy; 2023 Todos os direitos reservados.
      </footer>

    </body>
  )
}