import React from 'react';

import { FaPlug, FaHandHoldingUsd, FaWindowRestore, FaGraduationCap, FaHandshake, FaGlobe, FaPoll, FaMoneyBillWaveAlt, FaQuestionCircle } from 'react-icons/fa';
import { HiOutlineMenuAlt2, HiOutlineMenuAlt3 } from 'react-icons/hi';

import styles from './menu.module.scss';

export default function Menu({ goBack, categoria, categorias, handleCategoria, smallMenu, setSmallMenu }) {
    return(
        <div style={{width: smallMenu ? '3rem' : '12rem'}} className={styles.menu}>
            <div onClick={() => setSmallMenu(!smallMenu)} className={styles.toggleButton}>
                {smallMenu ? 
                    <HiOutlineMenuAlt3 color="var(--black)" size={25} />
                :
                    <HiOutlineMenuAlt2 color="var(--black)" size={25} />
                }
            </div>

            <ul style={{display: smallMenu ? 'none' : 'flex'}} className={styles.list}>
                {categorias.map((item, k) => (
                    <li style={{backgroundColor: categoria === item.id ? 'var(--gray-100)' : categoria === 0 ? null : null}} onClick={() => handleCategoria(item.id, item.titulo)} className={styles.title} key={k}>
                        {item.id === 1 ? <FaPlug color="var(--black)" size={17} /> : null}
                        {item.id === 2 ? <FaHandHoldingUsd color="var(--black)" size={17} /> : null}
                        {item.id === 3 ? <FaWindowRestore color="var(--black)" size={17} /> : null}
                        {item.id === 4 ? <FaHandshake color="var(--black)" size={17} /> : null}
                        {item.id === 5 ? <FaGlobe color="var(--black)" size={17} /> : null}
                        {item.id === 6 ? <FaPoll color="var(--black)" size={17} /> : null}
                        {item.id === 7 ? <FaMoneyBillWaveAlt color="var(--black)" size={17} /> : null}
                        {item.id === 8 ? <FaQuestionCircle color="var(--black)" size={17} /> : null}
                        {item.id === 9 ? <FaGraduationCap color="var(--black)" size={17} /> : null}
                        <span>{item.titulo}</span>
                    </li>
                ))}
            </ul>

            <ul style={{display: smallMenu ? 'flex' : 'none'}} className={styles.listMobile}>
                <li style={{backgroundColor: categoria === 1 ? 'var(--gray-100)' : null}} onClick={() => handleCategoria(1, 'Implementação')}><FaPlug color="var(--black)" size={17} /></li>
                <li style={{backgroundColor: categoria === 2 ? 'var(--gray-100)' : null}} onClick={() => handleCategoria(2, 'Repasse')}><FaHandHoldingUsd color="var(--black)" size={17} /></li>
                <li style={{backgroundColor: categoria === 3 ? 'var(--gray-100)' : null}} onClick={() => handleCategoria(3, 'Formatos')}><FaWindowRestore color="var(--black)" size={17} /></li>
                <li style={{backgroundColor: categoria === 4 ? 'var(--gray-100)' : null}} onClick={() => handleCategoria(4, 'Contrato')}><FaHandshake color="var(--black)" size={17} /></li>
                <li style={{backgroundColor: categoria === 5 ? 'var(--gray-100)' : null}} onClick={() => handleCategoria(5, 'Processo MCM')}><FaGlobe color="var(--black)" size={17} /></li>
                <li style={{backgroundColor: categoria === 6 ? 'var(--gray-100)' : null}} onClick={() => handleCategoria(6, 'Painel Dashboard')}><FaPoll color="var(--black)" size={17} /></li>
                <li style={{backgroundColor: categoria === 7 ? 'var(--gray-100)' : null}} onClick={() => handleCategoria(7, 'Financeiro')}><FaMoneyBillWaveAlt color="var(--black)" size={17} /></li>
                <li style={{backgroundColor: categoria === 8 ? 'var(--gray-100)' : null}} onClick={() => handleCategoria(8, 'Outras perguntas')}><FaQuestionCircle color="var(--black)" size={17} /></li>
                <li style={{backgroundColor: categoria === 9 ? 'var(--gray-100)' : null}} onClick={() => handleCategoria(9, 'Tutorias')}><FaGraduationCap color="var(--black)" size={17} /></li>
            </ul>
        </div>
    )
}