import React from 'react';
import styles from './privacidade.module.scss';

export default function Privacidade() {
    return(
        <section className={styles.privacity}>
            <h2>Política Privacidade</h2>
            <h3>Informações Gerais</h3>

            <p>A sua privacidade é importante para nós. É política da nobeta respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site, na plataforma e nos formatos da <a href="https://www.nobeta.com.br">nobeta</a>, e outros sites que possuímos e operamos.</p>
            <p>Enquanto dados, temos o objetivo de ajudar os usuários dos nossos serviços a entender da melhor forma a coleta, armazenamento, acesso e processamento dos dados proveniente da utilização dos nossos serviços.</p>
            <p>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer algum serviço. Fazemos por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.</p>
            <p>Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.</p>

            <p>Não vendemos ou compartilhamos informações de qualquer natureza publicamente ou com terceiros, exceto quando exigido pela legislação vigente, limitados estritamente ao necessário, apenas quando solicitado por autoridades públicas.</p>

            <h3>Dados e Cookies</h3>

            <p>Dados são todas as informações geradas entre a interação do usuário a qualquer serviço da nobeta, de maneira voluntária ou não. Para cada serviço, há diferentes tipo de coleta, o seu tratamento, armazenamento e utilização estão de acordo com as boas práticas de manipulação e segurança do mercado, visando sempre o respeito e confiança dos nossos utilizadores.</p>

            <p>Cookie é um pequeno arquivo enviado de um website para o computador ou um aparelho celular gerado pelo navegador. Este arquivo contém informações estritamente necessária para o funcionamento de nossos serviços. Mesmo assim, o usuário pode recusar os cookies apagando ou bloqueando no navegador:</p>
  
            <ul className={styles.ul}>
                <li> Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=pt-BR">https://support.google.com/chrome/answer/95647?hl=pt-BR</a></li>
                <li> Microsoft Edge: <a href="https://support.microsoft.com/pt-br/topic/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">https://support.microsoft.com/pt-br/topic/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d</a></li>
                <li> Mozilla FireFox: <a href="https://support.mozilla.org/pt-PT/kb/bloquear-os-sites-para-nao-guardarem-cookies-dados-site-firefox">https://support.mozilla.org/pt-PT/kb/bloquear-os-sites-para-nao-guardarem-cookies-dados-site-firefox</a></li>
                <li> Apple Safari: <a href="https://support.apple.com/pt-br/HT201265">https://support.apple.com/pt-br/HT201265</a></li>
                <li> Opera Browser (em inglês): <a href="https://www.opera.com/case-studies/clean-browser-and-remove-trackers">https://www.opera.com/case-studies/clean-browser-and-remove-trackers</a></li>
            </ul>
                <p>Para melhor entendimento, estamos separando este documento em 5 seções, a fim de tratar os detalhes que cada aspecto da interação entre a nobeta e nossos utilizadores, assim como os dados são coletados e como são tratados.</p>

                <h4>Site</h4>
                    <p>Ao acessar o site da nobeta. Os dados gerados de maneira involuntária e necessária pelo navegador, como informações de sistema operacional, nome e versão do navegador e endereço IP são armazenados de forma anonimizada e automática pelo serviço de hospedagem.</p>
                    <p>A nobeta utiliza os dados gerados pelo Google Analytics. Em conformidade com a politica de privacidade do Google disponível em detalhes no endereço <a href="https://www.google.com/intl/pt-BR/policies/privacy/partners/">https://www.google.com/intl/pt-BR/policies/privacy/partners/</a>,Os dados são estatísticos, e a utilização são para verificar o funcionamento do site e entendimento do nosso público-alvo.</p>
                    <p>O site da nobeta utiliza o serviço de conversação da Jivochat, para uma melhor resposta aos visitantes. O uso, armazenamento, transmissão e processamento dos dados coletados por este serviço está descrito em seu documento de política de privacidade encontrado no link:<a href="https://www.jivochat.com.br/files/privacy_policy.pdf">https://www.jivochat.com.br/files/privacy_policy.pdf</a>, os dados disponibilizados à nobeta são apenas para comunicação.</p>
                    <p>O formulário de contato disponível no nosso site tem como objetivo exclusivamente para a comunicação, os dados inseridos voluntariamente pelo usuário são armazenados de acordo com as boas práticas de segurança. Nenhum dado é compartilhado ou vendido para terceiros, temos a política de informar publicamente quaisquer eventual vazamento de dados, ações feita para rastrear, corrigir e minimizar o ocorrido, além instruir nossos usuários a reduzir os efeitos do vazamento, caso necessário.</p>
                    <p>O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.</p>

                <h4>Formatos de anúncios</h4>
                    <p>O script de entrega de anúncio da nobeta, gera dados relacionado à requisição de um endereço na web, esses dados são gerado de maneira involuntária, contendo dados de sistema operacional, nome e versão do navegador, endereço IP público, data/hora e página de referência, e é coletado, armazenado e processado pelo sistema de log do serviço cloud de maneira padrão, por 30 dias. Não há um processamento por parte da nobeta para esses dados.</p>
                    <p>A nobeta utiliza o servidor de anúncio do Google, a coleta, armazenamento, compartilhamento e processamento dos dados estão sob responsabilidade do Google, descrito na sua página de política de  privacidade, encontrada neste endereço: <a href="https://policies.google.com/technologies/partner-sites?hl=pt-BR">https://policies.google.com/technologies/partner-sites?hl=pt-BR</a>. Os dados disponibilizados pelo servidor de anúncio são sempre anonimizado e é utilizado pela nobeta como forma de contabilizar valores de impressões, cliques e receita gerada e ser disponibilizada pelo nosso painel de relatório com acesso restrito aos nossos parceiros. Os dados estarão disponíveis enquanto o serviço é prestado.</p>
                    <p>Nosso time de marketing faz uso de um tracking pixel disponibilizado pela ComScore, para contabilização de impressões, como forma de medição de inventário ao longo do tempo. Os relatórios disponibilizados por este serviço ( e pelo plano adquido ) são anonimizado e estatísticos, não há coleta de dados pessoais. A descrição do serviço, assim como o processo de opt-out podem ser encontrado no endereço: <a href="https://www.comscore.com/por/Sobre/Politica-de-Privacidade">https://www.comscore.com/por/Sobre/Politica-de-Privacidade</a></p>
                    <p>Nossos formatos utilizam tecnologia de Header Bidding, Alguns dados adicionais podem gerados pelo script de entrega por alguma rede conectada ao Header Bidding, assim como cookies, a nobeta <b>não</b> utiliza os dados gerados diretamente por esta tecnologia, todos os valores necessários para complementar o relatório de repasse são feitas via relatórios fornecidas por cada rede. Os dados incluem impressões, cliques e receita diárias. Nenhum dado de identificação pessoal é compartilhado.</p>
                    <p>Eventualmente, um formato de anúncio em um site pode gerar um cookie de controle de frequência, este cookie é um contador simples de acesso e serve para controlar a exibição ou não de anúncios em certos formatos no site do qual está implementado. O cookie pode ser identificado com os nomes:</p>
                    <ul className={styles.ul}>
                        <li>nbtinterFrequency_[identificador do site]</li>
                        <li>nbtalertFrequency_[identificador do site]</li>
                    </ul>
                    <p>Estes cookies são gerado sob o domínio do site onde o script está implementado, você ainda pode excluir ou bloquear esses cookies seguindo os passos na seção de cookies desta página. Lembre-se que estes cookies são de controle de frequência, ao excluir ou bloquear os anúncios serão entregue anúncios com mais frequência do que o esperado.</p>

                <h4>Painel de relatório</h4>
                    <p>Esta seção trata dos nossos parceiros. Ao entrar em contato com os nossos representantes, em qualquer canal, por meio de informações encontradas em pesquisas públicas e websites. Os dados coletados, de maneira voluntária, são armazenados e utilizados para comunicação durante a prestação de serviços.</p>
                    <p>Os dados pessoais coletados de contato são: nome, telefone e email. Para os parceiros com acordo de entrega de anúncios através de algum serviço da nobeta, também é coletado e armazenado dados bancários e de nota fiscal para o depósito do repasse gerado pela entrega de anúncio.</p>
                    <p>Ao acessar a área restrita do painel de relatório, um cookie de identificação com o nome <i>nbttoken</i> é gravado, sua finalidade é o controle de sessão dentro do domínio do relatório e é essencial para o funcionamento do serviço, o conteúdo do cookie é uma chave única que permite a sincronização dos dados referente a conta de usuário nos nossos servidores (api) com a ferramenta de exibição de dados (relatório), pedimos que nunca compartilhe essa chave a terceiros, sempre encerrar a sessão em computadores públicos. Salientamos que este identificador não é usado para outras finalidades, está restrito ao domínio report.nobeta.com.br.</p>
                    <p>Temos o compromisso de nunca compartilhar/vender esses dados a terceiros. A segurança dos seus dados pessoais é importante para nós. Todos os dados armazenados são monitorados e protegidos de acordo com as boas práticas de segurança do mercado. Também temos a política de informar publicamente quaisquer eventual vazamento de dados, ações feitas para rastrear, corrigir e minimizar o ocorrido, além instruir nossos usuários a reduzir os efeitos do vazamento, caso necessário.</p>
                    <p>A nobeta espera dos seus parceiros, a responsabilidade do sigilo de suas credenciais (nome de usuário e senha) e token de acesso. <b>Não compartilhe</b> esses dados a terceiros, caso há alguma identificação de uso desses dados por terceiros, a nobeta deve ser informada imediatamente para bloqueio ou alteração do acesso, além da isenção da nobeta decorrente ao resultado do acesso indevido.</p>

                <h4>Pixel de monitoramento</h4>
                    <p>Eventuamente, trabalhamos com agências terceiras para veiculação de anúncios e em alguns casos, é necessário acompanhar o desempenho da campanha de forma mais precisa, assim, algumas ferramentas desenvolvidas pela nobeta são usadas, como:</p>
                    <ul>
                        <li>Pixel de contagem de impressões</li>
                        <li>Pixel de contagem de cliques</li>
                        <li>Callback / Postback</li>
                        <li>Pixel de viewability de vídeos</li>
                        <li>Pixel de contagem de cadastro concluído (lead)</li>
                    </ul>
                    <p>Os pixel sempre serão identificados com o dominio da nobeta, nenhuma ferramenta terceira é usada no processo de captura dos dados, de forma que garantimos que nenhuma informação será enviada fora dos domínios da nobeta. Assim como nos formatos de anúncios, o sistema operacional e o servidor web podem coletar o nome e versão do navegador, endereço IP público, data/hora e página de referência, e esses campos também são coletados por uma obrigação legal pelo serviço cloud, por 30 dias. Esses dados são privados e são inacessíveis pelos funcionários da nobeta, com exceção da equipe técnica, que não utilizam estes dados, uma vez que, os campos constam também no banco de dados utilizados pelo pixel.</p>
                    <p>Todas as ferramentas descritas acima, não tem a capacidade de executar instruções de scripts no navegador, a resposta dos pixels de impressões, clique e viewability são uma imagem png transparente com as dimensões de 1 pixel de altura, por 1 pixel de largura, podendo ser requisitada via cliente ou via servidor, enquanto o pixel de lead e o postback retornam os dados recebidos, adicionando um identificador da operação de gravação em formato estático (JSON).</p>
                    <p>Os dados capturados pelos pixels de impressões, cliques e viewability, além das informações de acesso, também contém informações definidas nos parametros. Os pixels são configurados para receber dados da campanha e do criativo, sem a identificação do usuário, ou seja, <b>anonimizadas</b>.</p>
                    <p>Por sua vez, os dados recebidos pelo pixel de lead e da tag de postback contém informações do cadastro do usuário, de todos os campos enviados pela agência/cliente via parâmetro para nobeta, <b>com o propósito apenas de conferência entre as operações de gravação do banco de dados entre a agência e a nobeta</b>.</p>
                    <p>Algumas campanhas em que não há integração com CRM ou sistema similares, a nobeta disponibiliza um painel com os dados registrados em tempo real, usando as boas práticas de acesso, protegido por login e senha e para apenas para o pessoal da agência / cliente. Os dados são retirados do ar em 30 dias ou antes, quando solicidado pelo cliente a qualquer momento durante ou depois da campanha, a nobeta espera o bom uso das credenciais por parte da agência/cliente a fim de limitar o acesso e os dados disponibilizados apenas para o pessoal necessário.</p>
                    <p>Neste período, em posse destes dados, a nobeta se compromete a nunca compartilhar ou vender estas informações. Sempre trabalhamos para manter a segurança da informação, limitando o acesso interno e externo e monitorando de forma efetiva, também a nobeta se compromete a informar, investigar, corrigir e minimizar quaisquer vazamento de dados, assim como auxiliar os usuários a reduzir os efeitos do vazamento.</p>

                <h4>Publishers de Performance e Conexões Externas</h4>
                <p>
                    A nobeta disponibiliza um acesso ao painel de relatório de "Publishers de Performance", usando os dados de nome e email para a segurança e comunicação relacionado aos valores e outros detalhes técnicos sobre os sites. Nós nos comprometemos a nunca compartilhar ou vender estes dados, e esperamos dos nossos parceiros, o sigilo e proteção dos dados de credenciais fornecidos por nós. 
                </p>
                <p>
                    O painel de Publishers de Performance necessita de um acesso via API para sincronizar os dados do Google AdManager, a fim de organizar e informar o usuários sobre os ganhos em cada site por dia e um acesso opcional, no entanto, altamente recomendável aos dados das campanhas publicado no Facebook, através de uma conexão via login, dentro do painel. A seguir, mais detalhes sobre os procedimentos.
                </p>
                <h5>Google AdManager</h5>
                <p>
                    O acesso é feito via cadastro do nosso usuário de sistema denominado "nobeta api". Um serviço do lado da nobeta usa esta conexão apenas para sincronizar os dados de impressões, cliques e receita gerada para o nosso banco de dados, por dia, por país e por página; a fim de diminuir a complexidade, consolidar e exibir os dados no painel.
                </p>
                <p>
                    O nosso banco de dados tem um acesso, protegido por usuário e senha, além de ter um acesso limitado aos endereços IPs dos desenvolvedores envolvidos no projeto.
                </p>
                <p>
                    A API pode ser usada para criação de automações requisitados pelos parceiros, como criação de blocos de anúncios ou de palavras chaves de controle de entrega de anúncios, por padrão, os serviços são desativados, apenas ativados por autorização e acordo direta dos parceiros
                </p>
                <p>
                    Os dados consolidados podem ser acessada pela equipe de operações para analise e criação de recomendações para os parceiros.
                </p>
                <h5>Meta Facebook</h5>
                <p>
                    Um acesso opcional pode ser feita usando o login do Facebook e gerando um <i>"access token"</i>, para o painel de Publishers de Performance possa ter os dados de campanhas, os campos sincronizados são: Impressões, cliques e valores gerados, por dia e por campanha, em todas as contas de anúncios que o usuário faz parte, no entanto, apenas as campanhas selecionadas dentro do painel serão sincronizados pelo painel da nobeta.
                </p>
                <p>
                    O <i>"access token"</i> é gerado pela aplicação do Facebook, no momento da conexão (clique no botão de login), esta é uma operação <i>Sever-to-Server</i>, que significa que o token não será exposto pelo navegador, o <i>"access token"</i> será gravado no banco de dados, assim como os demais dados.
                </p>
                <p>
                    Como já mencionado, o banco de dados do serviço é protegido por usuário e senha e tem um acesso restrito por IPs previamente cadastrados dos desenvolvedores envolvidos no projeto. Sendo os dados sincronizados diariamente, até que o token expire ou seja revogado pelo usuário.
                </p>
                <p>
                    O DPO, informado no final deste documento, pode ser acionado para a remoção manual de todos os dados, incluindo o <i>"access token"</i> gerado e os demais dados, caso seja a vontade do usuário. Nos comprometemos a nunca divulgar, vender ou compartilhar estes dados com terceiros. Os dados serão usados de forma controlada pelos colaboradores internos a fim de gerar recomendações para os parceiros e o  cálculo de ROI.
                </p>
            

                <h3>Considerações Finais</h3>
                <p>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.</p>

                <p>O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.</p>

                <h3>Mais informações</h3>
                <p>Esperamos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.</p>
                <p>Em caso de dúvida e maiores esclarecimentos, entre em contato com a nobeta, por email, telefone ou qualquer canal disponíveis em relação a essa política de privacidade e os dados armazenados, além da solicitação de exclusão desses dados:</p>
                <ul className={styles.ul}>
                    <li>DPO: Ricardo Rodrigues</li>
                    <li>contato@nobeta.com.br</li>
                </ul>
                <p>Esta política é efetiva a partir de <strong>Novembro</strong>/<strong>2020</strong>. e foi atualizada dia 10/09/2024.</p>

        </section>
    )
}