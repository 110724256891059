import React, { useState } from 'react';

import validator from 'validator';
import { Button } from "rsuite";
import { Snackbar, Alert } from '@mui/material';           
                  
import BannerPublisher from '../../assets/images/banner_publisher.jpg';
import BannerAnunciante from '../../assets/images/banner_anunciante.jpg';
import BannerPublisher2x from '../../assets/images/banner_publisher-2x.jpg';
import BannerAnunciante2x from '../../assets/images/banner_anunciante-2x.jpg';
import BannerPublisher3x from '../../assets/images/banner_publisher-3x.jpg';
import BannerAnunciante3x from '../../assets/images/banner_anunciante-3x.jpg';

import { api } from '../../services/api';

import styles from './home.module.scss';

export default function Home() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [openSendEmail, setOpenSendEmail] = useState(false);
    const [typeSendEmail, setTypeSendEmail] = useState('');
    const [sendEmailMessage, setSendEmailMessage] = useState('');

    const [emailNewsletter, setEmailNewsletter] = useState('');
    const [typeEmailMessage, setTypeEmailMessage] = useState('');
    const [openEmailMessage, setOpenEmailMessage] = useState(false);
    
    function handleMessage() {
        if(name && email && message) {
            if(validator.isEmail(email)) {
                api.post('/nobeta/send-message', {
                    name: name,
                    email: email,
                    message: message
                }).then((res) => {
                    if(res.data.result) {
                        setName('');
                        setEmail('');
                        setMessage('');
        
                        setTypeSendEmail('success');
                        setSendEmailMessage('Seu email foi enviado, Obrigado!')
                    } else {
                        setSendEmailMessage('Algo deu errado, tente novamente')
                        setTypeSendEmail('error');
                    }
        
                    setOpenSendEmail(true);
                });
            } else {
                setOpenSendEmail(true);
                setTypeSendEmail('invalid');
                setSendEmailMessage('Email inválido')
            }
        } else {
            setOpenSendEmail(true);
            setTypeSendEmail('error');
            setSendEmailMessage('Todos os campos são obrigatórios')
        }
    }

    function handleNewsletter() {
        if(emailNewsletter && validator.isEmail(emailNewsletter)) {
            api.post('/nobeta/newsletter', {
                email: emailNewsletter
            }).then((res) => {
                if(res.data.result === 'Email já cadastrado') {
                    setTypeEmailMessage('error');
                } else {
                    setTypeEmailMessage('success');
                }

                setOpenEmailMessage(true);
            })
        } else {
            setOpenEmailMessage(true);
            setTypeEmailMessage('non-exist');
        }
    }

    return (
        <div className={styles.container}>
            <Snackbar 
                autoHideDuration={3000} 
                anchorOrigin={{vertical: 'top', horizontal: 'center'}} 
                onClose={() => setOpenEmailMessage(false)} 
                open={openEmailMessage}
            >
                <Alert onClose={() => setOpenEmailMessage(false)} severity={typeEmailMessage === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {typeEmailMessage === 'success' ? 'Email cadastrado com sucesso' : typeEmailMessage === 'error' ? 'Email já cadastrado' : 'Email inválido'}
                </Alert>
            </Snackbar>

            <Snackbar 
                autoHideDuration={3000} 
                anchorOrigin={{vertical: 'top', horizontal: 'center'}} 
                onClose={() => setOpenSendEmail(false)} 
                open={openSendEmail}
            >
                <Alert onClose={() => setOpenSendEmail(false)} severity={typeSendEmail === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {sendEmailMessage}
                </Alert>
            </Snackbar>

            <div className={styles.banner}>
                <div className={styles.innerBanner}>
                    <h1>Otimize seus ganhos com a Nobeta</h1>

                    <p>Aumente as possibilidades de receita extra com seu site, seja como anunciante ou parceiro. <a rel="noreferrer" href={"https://nobeta.com.br/anunciantes"} target="_blank">Saiba mais!</a></p>

                    <div className={styles.buttons}>
                        <a rel="noreferrer" href="/anunciantes" target="_blank">
                            <Button style={{height: '2.7rem', borderRadius: '2rem', backgroundColor: 'var(--secondary)', color: 'var(--black)'}} appearance={"primary"}>
                                Seja um anunciante
                            </Button>
                        </a>
                        
                        <a rel="noreferrer" href="https://parceria.nobeta.com.br/" target="_blank">
                            <Button style={{height: '2.7rem', borderRadius: '2rem', marginLeft: '1rem', backgroundColor: 'var(--primary)', color: 'var(--black)'}} appearance={"primary"}>
                                Seja um parceiro
                            </Button>
                        </a>
                    </div>
                </div>
            </div>

            <div className={styles.about}>
                <h2>Soluções de mídia para sites e anunciantes</h2>

                <p>
                    Somos um Adtech que desenvolve e gerencia espaços de mídia online, gerando oportunidades para
                    anunciantes atingirem o seu público alvo com campanhas tradicionais e especiais e garantindo que
                    sites tenham potencial de alavancar e valorizar sua audiência.
                </p>

                <p className={styles.secondaryColor}>
                    Ajudamos mais de 350 sites a compor sua receita de publicidade e entregamos campanhas com mais de
                    mil anunciantes mensalmente. Somos parceiros do Google e de diversas outras plataformas, nossos
                    formatos são aprovados e de acordo com políticas de boas práticas e tecnologia publicitária.
                </p>
            </div>
            
            
            <div className={styles.bannerexemplo}>
                <div className={styles.content}>
                    <div className={styles.text}>
                        <h1>Conheça os <span>formatos</span> proprietários da nobeta</h1>

                        <p>Encontre aqui as especificações e detalhes dos nossos formatos especiais para seu site.</p>

                        <a href="/exemplos" rel="noreferrer" className={styles.button}>Quero usar estes formatos</a>
                    </div>

                   
                </div>
            </div>

            


            <div className={styles.newsletter}>
                <div className={styles.content}>
                    <h2>Assine a newsletter Nobeta</h2>

                    <div className={styles.wrappedInput}>
                        <input onChange={e => setEmailNewsletter(e.target.value)} placeholder="Digite seu email" />
                        <div onClick={handleNewsletter} className={styles.inputButton}>Assinar</div>
                    </div>

                    <p>
                        Receba informações exclusivas da Nobeta. Nós nunca disponibilizaremos nossa lista para serviços terceiros, você
                        pode requisitar a remoção do seu endereço a qualquer momento.
                    </p>
                </div>
            </div>

            <div className={styles.membership}>
                <div className={styles.inlineItem}>
                    <div className={styles.text}>
                        <p>
                            Conheça nossas soluções de publicidade online para veicular sua campanha de display ou vídeo
                            com garantias de métricas pré acordadas através de compras diretas e por meio programático.
                        </p>

                        <a rel="noreferrer" href="https://nobeta.com.br/anunciantes" target="_blank" className={styles.buttonOutlineSecondary}>
                            <span>Saiba mais</span>
                        </a>
                    </div>

                    <img src={BannerPublisher} srcSet={`${BannerPublisher2x} 2x, ${BannerPublisher3x} 3x`} alt={"Banner Publisher"} width={550} height={400} />
                </div>

                <div className={styles.inlineItem}>
                    <div className={styles.text}>
                        <p>
                            Tenha em seu site formatos especiais e IAB com demandas de grandes anunciantes da Nobeta,
                            valorize sua audiência e gere um novo canal de receita para seu site.
                        </p>

                        <a rel="noreferrer" href="https://parceria.nobeta.com.br/" target="_blank" className={styles.buttonOutlinePrimary}>
                            <span>Saiba mais</span>
                        </a>
                    </div>

                    <img src={BannerAnunciante} srcSet={`${BannerAnunciante2x} 2x, ${BannerAnunciante3x} 3x`} alt={"Banner Anunciante"} width={550} height={400} />
                </div>
            </div>

            <div id="contact" className={styles.contact}>
                <form method="post">
                    <input style={{borderColor: typeSendEmail === 'error' ? 'var(--red)' : null}} value={name} onChange={v => setName(v.target.value)} className={styles.input} placeholder={"Seu Nome"} type={"text"}/>
                    <input style={{borderColor: typeSendEmail === 'error' || typeSendEmail === 'invalid' ? 'var(--red)' : null}} value={email} onChange={v => setEmail(v.target.value)} className={styles.input} placeholder={"Seu Email"} type={"email"}/>
                    <textarea style={{borderColor: typeSendEmail === 'error' ? 'var(--red)' : null}} value={message} onChange={v => setMessage(v.target.value)} className={styles.input} placeholder={"Sua mensagem"}/>

                    <div onClick={handleMessage} className={styles.submit}>Enviar Mensagem</div>
                </form>

                <div className={styles.info}>
                    <span className={styles.title}>Entre em contato conosco, baixe nosso mídia kit e anuncie com a nobeta.</span>

                    <p>Aguarde que um dos integrantes de nossa equipe irá lhe retornar brevemente</p>

                    <div className={styles.address}>
                        <span>Nobeta</span>
                        <span>Rua Belmiro Braga 46</span>
                        <span>Pinheiros</span>
                        <span>São Paulo/SP 05432-020</span>
                    </div>

                    <div className={styles.contactInfo}>
                        <span>Tel: +55(11) 4508-8226</span>
                        <a href={"mailto:debora@nobeta.com.br"}>contato@nobeta.com.br</a>
                    </div>

                    <a rel="noreferrer" href={"https://cdn.nobeta.com.br/midia/Nobeta_Midia_Kit_2021_light.pdf"} target={"_blank"}
                       className={styles.mediakit}>Baixar nosso mídia kit aqui.</a>
                </div>
            </div>
        </div>
    )
}