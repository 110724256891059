import React from 'react';

import './App.css';
import "./globals.scss";
import "rsuite/dist/rsuite.min.css";

import { Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import Privacidade from './pages/Privacidade';

import Home from './pages/Home';
import Documentation from './pages/Documentation';
import Anunciantes from './pages/Anunciantes';
import Pagina from './pages/Pagina';
import Exemplos from './pages/Exemplos';
import Preview from './pages/Preview';
import Formato from './pages/Formato';
import Formatos from './pages/Formatos';

function App() {
  return (
    <div>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/anunciantes" element={<Anunciantes />} />

        <Route path="/documentacao" element={<Documentation />} />

        <Route path="/documentacao/:categoria/:id/:title" element={<Pagina />} />

        <Route path="/politica-privacidade" element={<Privacidade />} />

        <Route path="/exemplos" element={<Exemplos />} />

        <Route path="/preview/:id" element={<Preview />} />

        <Route path="/formatos/:formato.html" element={<Formato />} />

        <Route path="/formatos/:formato" element={<Formatos />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
