import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';  

import styles from './anunciantes.module.scss';
import { apiadm } from '../../services/api';

const INICIAL = 0;
const VALIDO = 1;
const INVALIDO = 2;

export default function Anunciantes() {
    const navigate = useNavigate();

    const [dados, setDados] = useState({
        "nome": "",
        "agencia": "",
        "telefone": "",
        "email": ""
    });

    const [validado, setValidado] = useState({
        "nome": INICIAL,
        "agencia": INICIAL,
        "email": INICIAL
    });

    const [enviando, setEnviando] = useState(false);
    const [enviado, setEnviado] = useState(false);
    
    useEffect(() => {
        
    }, []);

    function handleDados( data ){
        let _dados = { ... dados };
        _dados[ data.name ] = data.value;
        setDados( _dados );
    }

    function confirmacao(){
        setEnviado(true);
    }

    function alterarStatus( campo, status ){
        let _dados = {... validado};
        _dados[campo] = status;
        setValidado( _dados );
    }

    function validacao(){
        if( dados.nome.length == 0 ){
            alterarStatus('nome', INVALIDO);
            return false;
        }

        if( dados.agencia.length == 0 ){
            alterarStatus('agencia', INVALIDO);
            return false;
        }

        if( dados.email.length == 0 ){
            alterarStatus('email', INVALIDO);
            return false;
        }

        return true;
    }

    function resetStatus(){
        setValidado({
            "nome": INICIAL,
            "agencia": INICIAL,
            "email": INICIAL
        });
    }

    function enviarDados(){
        if( enviando == true ) return;
        if( !validacao() ) return;
        resetStatus();
        setEnviando( true );
        apiadm.post('/email/anunciantes', dados)
          .then(function (response) {
            confirmacao();
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    return(
        <div className={styles.container}>
            <div className={styles.banner}>
                <div className={styles.lefttext}>
                    <h1>Seja um Anunciante!</h1>

                    <p>Anunciar na nobeta é uma ótima oportunidade para divulgar seu produto ou serviço para um público qualificado e interessado no seu segmento. Faça parte da nossa rede de anúnciantes!</p>
                </div>

                <div className={styles.shape}>
                <svg xmlns="http://www.w3.org/2000/svg" fill='#450372' viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path d="M0,6V0h1000v100L0,6z"></path>
                </svg>
                </div>
            </div>

            <section>

                { enviado ? (
                    <div className={ styles.warning }>
                        Obrigado, Dados enviado, em breve entraremos em contato.
                    </div>
                ) : (<>
                    <p className={ styles.paragrafo }>
                        Se você tem interesse em anunciar na nobeta, preencha o formulário abaixo com seus dados. Em breve, entraremos em contato com você para confirmar sua solicitação e informar os valores e as formas de pagamento. 
                    </p>
                    <div className={ styles.formcontrol }>
                        <label>Nome</label>
                        <input onChange={ e => handleDados( e.target ) } name="nome" className={styles.input}
                            type="text" placeholder="Digite seu nome" />
                            { validado.nome === INVALIDO && (
                                <span>Campo Obrigatório, por favor, digite seu nome</span>
                            ) }
                        
                    </div>

                    <div className={ styles.formcontrol }>
                        <label>Agência</label>
                        <input onChange={ e => handleDados( e.target ) } name="agencia" className={styles.input}
                            type="text" placeholder="Digite a sua agência" />
                        { validado.agencia === INVALIDO && (
                            <span>Campo Obrigatório, por favor, digite o nome da sua agência</span>
                        ) }
                        
                    </div>

                    <div className={ styles.formcontrol }>
                        <label>Telefone</label>
                        <input onChange={ e => handleDados( e.target ) } name="telefone" className={styles.input}
                            type="phone" placeholder="Digite a sua telefone" />
                    </div>

                    <div className={ styles.formcontrol }>
                        <label>E-mail</label>
                        <input onChange={ e => handleDados( e.target ) } name="email" className={styles.input}
                            type="email" placeholder="Digite a seu email" />
                        { validado.email === INVALIDO && (
                                <span>Campo Obrigatório, por favor, digite seu email</span>
                        ) }
                    </div>

                    <div className={ styles.buttons }>
                        <button onClick={ enviarDados } disabled={ enviando }
                            className={ styles.inputButton }>{ enviando ? 'Enviando' : 'Enviar'}</button>
                    </div>
                </>) }
                

                
                

            </section>
        </div>
    )
}