import React, { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import styles from './formatos.module.scss';
import './images.scss';

import { AiFillCaretDown } from 'react-icons/ai';
// import { TfiAngleLeft, TfiAngleRight } from 'react-icons/tfi';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import alert from '../../data/alert.json';
import intercontent from '../../data/intercontent.json';
import preparagraph from '../../data/preparagraph.json';
import ft from '../../data/ft.json';

const json = [
    {id: 1, prefix: 'intercontent', name: 'Intercontent'},
    {id: 2, prefix: 'alert', name: 'Alert'},
    {id: 3, prefix: 'preparagraph', name: 'Preparagraph'},
    {id: 4, prefix: 'ft', name: 'Footer'},
];

export default function Formatos () {
    const [template, setTemplate] = useState(0);
    const [formatoJson, setFormatoJson] = useState({});
    const [dropdown, setDropdown] = useState(false);

    const navigate = useNavigate();
    let { formato } = useParams();

    useEffect(() => {
        if(formato === 'intercontent') {
            setFormatoJson(intercontent);
        }

        if(formato === 'alert') {
            setFormatoJson(alert);
        }

        if(formato === 'preparagraph') {
            setFormatoJson(preparagraph);
        }

        if(formato === 'ft') {
            setFormatoJson(ft);
        }
    }, []);

    function previousFormato() {
        if(formato === 'ft') {
            window.history.replaceState(null, 'Intercontent', "/formatos/preparagraph");
        } else if (formato === 'preparagraph') {
            window.history.replaceState(null, 'Preparagraph', "/formatos/intercontent");
        } else if (formato === 'intercontent') {
            window.history.replaceState(null, 'Teste', "/formatos/alert");
        }

        window.location.reload(true);
    }

    function nextFormato() {
        if(formato === 'alert') {
            window.history.replaceState(null, 'Intercontent', "/formatos/intercontent");
        } else if (formato === 'intercontent') {
            window.history.replaceState(null, 'Preparagraph', "/formatos/preparagraph");
        } else if (formato === 'preparagraph') {
            window.history.replaceState(null, 'Teste', "/formatos/ft");
        }

        window.location.reload(true);
    }

    function goToFormato(name, prefix) {
        window.history.replaceState(null, name, `/formatos/${prefix}`);
        window.location.reload(true);
    }

    return(
        <div className={styles.container} id="container">
            <div className={styles.center}>
                <div className={styles.title} onClick={() => setDropdown(!dropdown)}>
                    <h1>{formatoJson.label}</h1>
                    <AiFillCaretDown color="#000" size={20} />

                    <div style={{display: dropdown ? 'flex' : 'none'}} className={styles.dropdown}>
                        {json.map((item, k) => (
                            <div onClick={() => goToFormato(item.name, item.prefix)} key={k}>
                                <span>{item.name}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.viewType}>
                    <div onClick={() => setTemplate(0)} style={{backgroundColor: template === 0 ? 'var(--exeprim)' : undefined}} className={styles.button}>
                        <span style={{color: template === 0 ? '#fff' : 'var(--exeprim)'}}>Mobile</span>
                    </div>

                    <div className={styles.line}></div>

                    <div onClick={() => setTemplate(1)} style={{backgroundColor: template === 1 ? 'var(--exeprim)' : undefined}} className={styles.button}>
                        <span style={{color: template === 1 ? '#fff' : 'var(--exeprim)'}}>Desktop</span>
                    </div>
                </div>

                <div className={styles.formato}>
                    <button onClick={previousFormato} disabled={formato === 'alert' ? true : false} className={styles.button}>
                        <FaAngleLeft size={20} />
                    </button>

                    <div id="template" className={template === 0 ? 'templatemobile' : 'templatedesktop'}>
                        <div id="header"></div>

                        <div id="content">
                            <div id="main">
                                <div id="title"></div>
                                <div id="subtitle"></div>
                                
                                {formato === 'preparagraph' ?
                                    <div id="nbtpreparagraph" className='preparagraph'></div>
                                :
                                    null
                                }

                                <div id="image">
                                    {formato === 'io' ?
                                        <div id="nbtio" className='io'>
                                            <div className='ads'></div>
                                        </div>
                                    :
                                        null
                                    }
                                </div>

                                <div className='line'></div>
                                <div className='line'></div>
                                <div className='line'></div>

                                {formato === 'dt' ? 
                                    <div id="nbtdtm" className='dtm'></div>
                                :
                                    null
                                }

                                <div className='line'></div>
                                <div className='linef'></div>
                            </div>

                            <div id="side">
                                <div className='news'></div>
                                {formato === 'dt' ?
                                    <div id="nbtdt" className='dt'></div>
                                :
                                    null
                                }

                                <div className='news'></div>
                                <div className='news'></div>
                            </div>
                        </div>

                        {formatoJson.topbar ? 
                            <div id="formato" className={formato}>
                                <div className="topbar">
                                    <div className={`${formato}close`}>
                                        {formatoJson.topbartext}
                                    </div>
                                </div>
                            </div>
                        :
                            null
                        }
                    </div>

                    <button onClick={nextFormato} disabled={formato === 'ft' ? true : false} className={styles.button}>
                        <FaAngleRight size={20} />
                    </button>
                </div>

                <a target="_blank" href={`../formatos/${formato}.html`} className={styles.buttonPreview}>Ver live preview</a>
            </div>

            <div className={styles.content}>
                {formatoJson.description?.map((item, k) => (
                    <p key={k}>{item}</p>
                ))}

                <strong>Especificações técnicas</strong>

                <ul style={{marginTop: 5}}>
                    <li>Aceita Tag de criativos, ou material.</li>
                    <li>Extensões de display: Jpeg, Png, Gif, Html5</li>
                    <li>Dimensões de display rich media (HTML5) em desktop: 1024x768 observando a área útil de 500px de altura, para evitar cortes em algumas resoluções de monitor, ou seja, coloque os elementos importante do criativo dentro desta área</li>
                    <li>Dimensões de display rich media (HTML5) em mobile: 320x480</li>
                    <li>Extensões de vídeo: Mp4, Mov, ou Url do Youtube.</li>
                    <li>Peso: até 1MB</li>
                </ul>

                <strong>Renderização</strong>

                <p>{formatoJson.specs?.render}</p>
            </div>

            <div className={styles.sizes}>
                <div className={styles.card}>
                    <div className={styles.topbar}>
                        <strong>Mobile</strong>
                    </div>

                    {formatoJson.specs?.mobile.map((item, k) => (
                        <div key={k} className={styles.bottombar}>
                            <div className={styles.size}>
                                <span>Largura</span>
                                <strong>{item[0]}px</strong>
                            </div>

                            <div className={styles.size}>
                                <span>Altura</span>
                                <strong>{item[1]}px</strong>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={styles.card}>
                    <div className={styles.topbar}>
                        <strong>Desktop</strong>
                    </div>

                    {formatoJson.specs?.desktop.map((item, k) => (
                        <div key={k} className={styles.bottombar}>
                            <div className={styles.size}>
                                <span>Largura</span>
                                <strong>{item[0]}px</strong>
                            </div>

                            <div className={styles.size}>
                                <span>Altura</span>
                                <strong>{item[1]}px</strong>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}