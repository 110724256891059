import {ReactComponent as ImgAlert} from '../assets/svg/alert.svg';
import {ReactComponent as ImgInter} from '../assets/svg/inter.svg';
import {ReactComponent as ImgPP} from '../assets/svg/pp.svg';
import {ReactComponent as ImgFt} from '../assets/svg/ft.svg';
import {ReactComponent as ImgFc} from '../assets/svg/fc.svg';
import {ReactComponent as ImgSticky} from '../assets/svg/sticky.svg';

export const formatos = [
    {id: 1, name: 'Alert', prefix: 'alert', img: ImgAlert, text: 'Push de navegador com alta visibilidade'},
    {id: 2, name: 'Intercontent', prefix: 'intercontent', img: ImgInter, text: 'Alta visibilidade com interação'},
    {id: 3, name: 'PreParagraph', prefix: 'preparagraph', img: ImgPP, text :'60% de Viewability garantido',},
    {id: 4, name: 'Footer', prefix: 'ft', img: ImgFt, text: 'Seu anúncio percebido sem ser agressivo'},
    // {id: 5, name: 'Flying Carpet', prefix: 'fc', img: ImgFc, text: 'Anúncios discretos entre parágrafos'},
    // {id: 6, name: 'AMP Sticky Ads', prefix: 'sticky', img: ImgSticky, text: 'Exclusivo para páginas AMP em mobile'},
];
