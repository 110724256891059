import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { AiOutlineDesktop, AiOutlineMobile, AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import { FaFacebookF, FaLinkedinIn, FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import { formatos } from '../../arrays/formatos';
import { cases } from '../../data/cases';
import ImgEven from '../../assets/images/even_desk.jpg';

import ImgBanner from '../../assets/images/banner.png';
import BackgroundImg from '../../assets/svg/background.svg';

import styles from './exemplos.module.scss';
import './exemplos.css';
import Header from '../../components/Header';

function App() {
  const [currentCase, setCase] = useState(1);

  const navigate = useNavigate();

  function scrollToPreviousFormato() {
    let scroll = document.getElementById("formatos");

    if(scroll !== null) {
      scroll.scrollLeft -= 200;
    }
  }

  function scrollToNextFormato() {
    let scroll = document.getElementById("formatos");

    if(scroll !== null) {
      scroll.scrollLeft += 200;
    }
  }

  function nextCase () {
    if(currentCase < 9) {
      setCase(currentCase + 1);

      let nextcase = document.getElementById('case');
      nextcase?.setAttribute("id", "nextcase");

      setTimeout(() => {
        nextcase?.setAttribute("id", "case");
      }, 500)
    }
  }

  function previousCase() {
    if(currentCase > 1) {
      setCase(currentCase - 1);

      let previouscase = document.getElementById('case');
      previouscase?.setAttribute("id", "previouscase");

      setTimeout(() => {
        previouscase?.setAttribute("id", "case");
      }, 500)
    } 
  }

  return (
    <div className={styles.container}>
      {/* <img src={BackgroundImg} className={styles.backgroundBanner} /> */}

      <div className={styles.banner}>
        <div className={styles.content}>
          <div className={styles.text}>
            <h1>Conheça os <span>formatos</span> da proprietários da nobeta</h1>

            <p>Encontre aqui as especificações e detalhes dos nossos formatos especiais para seu site.</p>

            <a href="https://parceria.nobeta.com.br/" target="_blank" rel="noreferrer" className={styles.button}>Quero usar estes formatos</a>
          </div>

          <div className={styles.examples}>
            <div className={styles.menuleft} onClick={scrollToPreviousFormato}>
              <FaAngleLeft color="#fff" size={20} />
            </div>

            <div className={styles.formatos} id="formatos">
              {formatos.map((item, k) => (
                <div className={styles.card}>
                  <div className={styles.top}>
                    <span>{item.name}</span>
                  </div>

                  <div className={styles.content}>
                    <p>{item.text}</p>
                    <item.img className={styles.img} />
                  </div>

                  <div className={styles.bottom}>
                    <a rel="norefferrer" target='_blank' href={`/formatos/${item.prefix}`} className={styles.buttonespec}>
                      <span>Especificações</span>
                    </a>

                    <a rel="noreferrer" target="_blank" href={`/formatos/${item.prefix}.html`} className={styles.buttonprev}>
                      <span>Preview</span>
                    </a>
                  </div>
                </div>
              ))}
            </div>

            <div className={styles.menuright} onClick={scrollToNextFormato}>
                <FaAngleRight color="#fff" size={20} />
              </div>
          </div>
        </div>
      </div>
      
      <div className={styles.cases}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div className={styles.line}></div>
            <h2>Cases</h2>
          </div>

          <div className={styles.menunavigation}>
            <div style={{backgroundColor: currentCase === 1 ? '#ccc' : undefined, cursor: currentCase === 1 ? 'not-allowed' : undefined}} className={styles.menuleft} onClick={previousCase} id="buttonleft">
              <FaAngleLeft color="#fff" size={20} />
            </div>

            <span className={styles.line}></span>

            <div style={{backgroundColor: currentCase === 9 ? '#ccc' : undefined, cursor: currentCase === 9 ? 'not-allowed' : undefined}} className={styles.menuright} onClick={nextCase} id="buttonright">
              <FaAngleRight color="#fff" size={20} />
            </div>
          </div>
        </div>

        <div className={styles.content} id="case">
          {cases.map((item, k) => (
            item.id === currentCase ? 
              <>
              <div className={styles.left}>
                <div className={styles.devices}>
                  <a style={{borderRadius: item.urlmobile ? '.5rem 0 0 .5rem' : '.5rem'}} rel="noreferrer" target="_blank" href={"#"} className={styles.device}>
                    <AiOutlineDesktop color="#fff" size={18} />
                    <span>Desktop</span>
                  </a>

                  {item.urlmobile ? 
                    <>
                      <div className={styles.line}></div>

                      <a rel="noreferrer" target="_blank" href={"#"} className={styles.device}>
                        <AiOutlineMobile color="#fff" size={18} />
                        <span>Mobile</span>
                      </a>
                    </>                  
                  :
                    null
                  }
                </div>

                <div className={styles.text}>
                  <h2>{item.title}</h2>
                  <p>Proof of concepts</p>
                </div>
              </div>

              <div className={styles.right}>
                <div className={styles.image}>
                  <img src={item.previewdesk} alt="Print" />
                </div>
              </div>
            </>

            : 

            null
          ))}
        </div>
      </div>
        
    </div>
  );
}

export default App;
